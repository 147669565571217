<template>
  <div>
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-0"
      :item-class="rowClass"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      hide-default-footer
      :header-props="{ 'sort-icon': 'bi bi-caret-up-fill' }"
      no-data-text="No se encuentra información"
      no-results-text="No se encuentra información"
    >
      <template v-slot:[`item.fecha_pago`]="{ item }">
        {{ item.fecha_pago | formatDatePay }}
      </template>
      <template v-slot:[`item.usuario`]="{ item }">
        {{ item.usuario | rutFormat }}
      </template>

      <template v-slot:[`item.rut`]="{ item }">
        {{ item.rut | rutFormat }}
      </template>

      <template v-slot:[`item.size_archivo`]="{ item }">
        <div v-if="item.size_archivo">
          {{ item.size_archivo | formatBytes }}
        </div>
        <div v-else>0.00 MB</div>
      </template>

      <template v-slot:[`item.fecha_creacion`]="{ item }">
        <span>
          {{ item.fecha_creacion | formatDate }}
        </span>
      </template>
      <template v-slot:[`item.fecha_creacion_ci`]="{ item }">
        {{ item.fecha_creacion_ci | formatDateDocTwo }}
      </template>
      <template v-slot:[`item.fecha_creacion_doc`]="{ item }">
        {{ item.fecha_creacion_doc | formatDate("doc") }}
      </template>
      <template v-slot:[`item.fecha_creacion_firma`]="{ item }">
        {{ item.fecha_creacion_firma | formatDateDocTwo }}
      </template>

      <template v-slot:[`item.down`]="{ item }">
        <!-- <v-btn
          @click="ActionButton(item)"
          text
          href="/images/myw3schoolsimage.jpg"
          download
        > -->
        <a @click.prevent="ActionButton(item)" class="black--text">
          <i :href="item" download color="black" class="icon bi bi-download">{{
            item.down
          }}</i>
        </a>
        <!-- </v-btn> -->
      </template>
      <template v-slot:[`item.ci_action`]="{ item }">
        <!-- <v-btn
          @click="ActionButton(item)"
          text
          href="/images/myw3schoolsimage.jpg"
          download
        > -->
        <v-icon color="#050C42" class="mr-1" @click="ShowButton(item)"
          >bi bi-eye</v-icon
        >
        <v-icon color="#050C42" class="ml-1" @click="DownloadButton(item)"
          >bi bi-box-arrow-down</v-icon
        >
        <!-- </v-btn> -->
      </template>

      <template v-slot:[`item.plazo`]="{ item }">
        <v-chip v-if="item.venta === 'V'">
          <v-row align="center" justify="center">
            <v-col cols="3">
              <v-badge
                dot
                v-if="parseInt(item.plazo) >= 1 && parseInt(item.plazo) <= 45"
                color="#F2C94C"
              >
              </v-badge>
              <v-badge
                dot
                v-if="parseInt(item.plazo) >= 46 && parseInt(item.plazo) <= 90"
                color="#EE2E22"
              >
              </v-badge>
            </v-col>

            <v-col cols="auto">
              <h4 class="state">{{ item.plazo }} Días</h4></v-col
            >
          </v-row>
        </v-chip>
        <v-chip v-if="item.venta === 'N'">
          <v-row align="center" justify="center">
            <v-col cols="3">
              <v-badge
                dot
                v-if="item.plazo >= 1 && item.plazo <= 15"
                color="#27AE60"
              >
              </v-badge>
              <v-badge
                dot
                v-else-if="
                  parseInt(item.plazo) >= 16 && parseInt(item.plazo) <= 30
                "
                color="#F2C94C"
              ></v-badge>
              <v-badge dot v-else color="#EE2E22"> </v-badge>
            </v-col>

            <v-col cols="auto">
              <h4 class="state">{{ item.plazo }} Días</h4></v-col
            >
          </v-row>
        </v-chip>
      </template>
      <template v-slot:[`item.monto_total`]="{ item }">
        {{ item.monto_total | moneyFormat }}
      </template>

      <template v-slot:[`item.monto_desc`]="{ item }">
        {{ item.monto_desc | moneyFormat }}
      </template>

      <template v-slot:[`item.descuento_porcentaje`]="{ item }">
        {{ item.descuento_porcentaje + "%" }}
      </template>

      <template v-slot:[`item.monto_moneda_local`]="{ item }">
        {{ item.simbolo_moneda_local
        }}{{ item.monto_moneda_local | moneyFormat }}
      </template>

      <template v-slot:[`item.plazo_retoma`]="{ item }">
        <v-hover>
          <div>
            <v-tooltip bottom style="background-color: red">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  @mouseover="select(item.plazo_retoma)"
                  @mouseleave="unselect"
                >
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-badge
                        dot
                        v-if="
                          parseInt(item.plazo_retoma[0].plazo_retoma) >= 1 &&
                          parseInt(item.plazo_retoma[0].plazo_retoma) <= 3
                        "
                        color="#EE2E22"
                      >
                      </v-badge>
                      <v-badge
                        dot
                        v-if="
                          parseInt(item.plazo_retoma[0].plazo_retoma) >= 4 &&
                          parseInt(item.plazo_retoma[0].plazo_retoma) <= 6
                        "
                        color="#F2C94C"
                      >
                      </v-badge>
                      <v-badge
                        dot
                        v-if="parseInt(item.plazo_retoma[0].plazo_retoma) >= 7"
                        color="#27AE60"
                      >
                      </v-badge>
                    </v-col>

                    <v-col cols="8">
                      <h4 class="state">
                        {{ item.plazo_retoma[0].plazo_retoma }} Días
                      </h4></v-col
                    >
                  </v-row>
                </v-chip>
              </template>
              <div v-if="item.plazo_retoma.length > 1">
                <h3
                  class="textStyle"
                  v-for="(it, index) in item.plazo_retoma"
                  :key="index"
                >
                  {{ it.compania }} / Deducible {{ it.deducible }} / Plan
                  {{ it.plan }}: {{ it.plazo_retoma }} días
                </h3>
              </div>
            </v-tooltip>
          </div>
        </v-hover>
      </template>
      <template v-slot:[`item.eyes`]="{ item }">
        <v-btn text @click="ActionButton(item)">
          <i class="icon bi bi-eye">{{ item.eyes }}</i>
        </v-btn>
      </template>

      <template v-slot:[`item.arrow`]="{ item }">
        <v-btn @click="ActionButton(item)" text>
          <i class="icon bi bi-arrow-up-right-circle">{{ item.arrow }}</i>
        </v-btn>
      </template>

      <template v-slot:[`item.shield`]="{ item }">
        <v-btn :disabled="isRejected(item)" @click="ActionButton(item)" text>
          <i class="icon bi bi-shield-lock">{{ item.shield }}</i>
        </v-btn>
      </template>

      <template v-slot:[`item.estado`]="{ item }">
        <v-chip color="#f2f2f2">
          <!-- @click="item.estado = !item.estado" -->
          <v-row align="center" justify="center">
            <!-- <v-col cols="1">
              <v-badge dot color="#F2994A" />
            </v-col> -->

            <v-col class="d-flex" v-if="item.estado === 'REC'">
              <v-badge class="badge" dot color="red" />
              <h4 class="state">Rechazado</h4>
            </v-col>

            <v-col class="d-flex" v-if="item.estado === 'A'">
              <v-badge class="badge" dot color="#F2994A" />
              <h4 class="state">Abierta</h4>
            </v-col>

            <v-col class="d-flex" v-else-if="item.estado === 'SEL'">
              <v-badge class="badge" dot color="#27AE60" />
              <h4 class="state">Seleccionada</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-else-if="item.estado === 'FAL' || item.estado === 'F'"
            >
              <v-badge class="badge" dot color="#F2C94C" />
              <h4 class="state">Falta Información</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-else-if="item.estado === 'PEN' || item.estado == 1"
            >
              <v-badge class="badge" dot color="#F2C94C" />
              <h4 class="state">Pendiente</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-else-if="item.estado === 'V' || item.estado == 2"
            >
              <v-badge class="badge" dot color="red" />
              <h4 class="state">Vencido</h4>
            </v-col>

            <v-col class="d-flex" v-else-if="item.estado === 'C'">
              <v-badge class="badge" dot color="blue" />
              <h4 class="state">Cerrada</h4>
            </v-col>

            <v-col class="d-flex" v-else>
              <v-badge class="badge" dot color="#F2C94C" />
              <h4 class="state">Falta Informacion</h4>
            </v-col>
          </v-row>
        </v-chip>
      </template>

      <template v-slot:[`item.estado_general_descripcion`]="{ item }">
        <v-chip color="#f2f2f2">
          <!-- @click="item.estado = !item.estado" -->
          <v-row align="center" justify="center">
            <!-- <v-col cols="1">
              <v-badge dot color="#F2994A" />
            </v-col> -->

            <v-col
              class="d-flex"
              v-if="item.estado_general_descripcion === 'Rechazado'"
            >
              <v-badge class="badge" dot color="red" />
              <h4 class="state">Rechazado</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-if="item.estado_general_descripcion === 'Abierta'"
            >
              <v-badge class="badge" dot color="#F2994A" />
              <h4 class="state">Abierta</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-else-if="item.estado_general_descripcion === 'SEL'"
            >
              <v-badge class="badge" dot color="#27AE60" />
              <h4 class="state">Selecionada</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-else-if="item.estado_general_descripcion === 'Pendiente'"
            >
              <v-badge class="badge" dot color="#F2C94C" />
              <h4 class="state">Pendiente</h4>
            </v-col>

            <v-col
              class="d-flex"
              v-else-if="
                item.estado_general_descripcion === 'V' || item.estado == 2
              "
            >
              <v-badge class="badge" dot color="red" />
              <h4 class="state">Vencido</h4>
            </v-col>
          </v-row>
        </v-chip>
      </template>

      <template v-slot:[`item.estado_general`]="{ item }">
        <v-chip color="#f2f2f2">
          <!-- @click="item.estado = !item.estado" -->
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-badge dot v-if="item.estado_general === 'ER'" color="#4F4F4F">
              </v-badge>
              <v-badge dot v-if="item.estado_general === 'R'" color="#EE2E22">
              </v-badge>
              <v-badge dot v-if="item.estado_general === 'PEN'" color="#FC9C0D">
              </v-badge>
            </v-col>
            <v-col cols="auto" v-if="item.estado_general === 'ER'">
              <h4 class="state">En revisión</h4></v-col
            >
            <v-col cols="auto" v-if="item.estado_general === 'R'">
              <h4 class="state">Rechazado</h4></v-col
            >
            <v-col cols="auto" v-if="item.estado_general === 'PEN'">
              <h4 class="state">Pendiente</h4></v-col
            >
          </v-row>
        </v-chip>
      </template>

      <template v-slot:[`item.estado_nombre`]="{ item }">
        <v-chip color="#f2f2f2">
          <!-- @click="item.estado = !item.estado" -->
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-badge
                dot
                v-if="item.estado_nombre === 'Pendiente'"
                color="#F2994A"
              >
              </v-badge>
              <v-badge
                dot
                v-if="item.estado_nombre === 'Pagado'"
                color="#27AE60"
              >
              </v-badge>
            </v-col>
            <v-col cols="auto" v-if="item.estado_nombre === 'Pagado'">
              <h4 class="state">Pagado</h4></v-col
            >
            <v-col cols="auto" v-if="item.estado_nombre === 'Pendiente'">
              <h4 class="state">Pendiente</h4></v-col
            >
          </v-row>
        </v-chip>
      </template>
      <template slot="no-data">
        <div>No se encuentra información</div>
      </template>
    </v-data-table>
    <v-layout v-if="footer && items.length > 10" class="align-center mt-3 ml-1">
      <h4 class="counterStyle">
        {{ 1 + itemsPerPage * (page - 1) }} al {{ itemsPerPage * page }}
      </h4>
      <v-flex xs1 class="ml-3">
        <v-select
          dense
          outlined
          hide-details
          single-line
          :value="itemsPerPage"
          @change="itemsPerPage = parseInt($event, 10)"
          :items="perPageChoices"
        >
        </v-select>
      </v-flex>
      <v-flex class="text-end">
        <v-btn
          elevation="0"
          :disabled="prevBtnDisabled === true"
          @click="prevPage"
          class="mr-1"
          ><v-icon x-small class="mr-2">bi bi-chevron-double-left</v-icon>
          Anterior</v-btn
        >
        <v-btn
          class="ml-1"
          elevation="0"
          :disabled="nextBtnDisabled === true"
          @click="nextPage"
          >Siguiente
          <v-icon x-small class="ml-2"
            >bi bi-chevron-double-right</v-icon
          ></v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "simpletable",
  props: {
    items: {
      type: [Array],
      require: true,
      default: () => [],
    },
    headers: {
      type: [Array],
      require: true,
    },
    footer: {
      type: Boolean,
      require: false,
    },
    search: {
      type: [String, Number],
      require: true,
    },
    actionIcon: {
      type: [String, Number, Boolean],
      require: true,
    },
    ActionButton: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
    DownloadButton: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
    ShowButton: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
    ShowPreview: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
    sortDesc: {
      type: String,
    },
  },
  data: () => ({
    page: 1,
    itemSelected: null,
    itemsPerPage: 10,
    nextBtnDisabled: false,
    prevBtnDisabled: true,
    perPageChoices: [
      { text: "10 ", value: 10 },
      { text: "15 ", value: 15 },
      { text: "20 ", value: 20 },
    ],
  }),
  mounted() {
    console.log("count pages: ", this.pageCount);
    console.log("total records: ", this.totalRecords);
  },
  methods: {
    plazoRetoma() {
      console.log("eee");
    },
    nextPage() {
      console.log("page: ", this.page);
      console.log("page count: ", this.pageCount);
      if (this.page < this.pageCount) this.page++;
      if (this.page === this.pageCount) {
        this.nextBtnDisabled = true;
        this.prevBtnDisabled = false;
      } else this.prevBtnDisabled = false;
    },

    prevPage() {
      console.log("page: ", this.page);
      console.log("page count: ", this.pageCount);
      if (this.page > 1) this.page--;
      if (this.page === 1) {
        this.prevBtnDisabled = true;
        this.nextBtnDisabled = false;
      } else this.nextBtnDisabled = false;
    },

    greeting() {
      this.$router.push("about");
    },

    rowClass() {
      const rowClass = "myclass";
      return rowClass;
    },
    select(item) {
      this.itemSelected = item;
    },
    isRejected(item) {
      if (item.estado_descripcion === "Aprobado") {
        return true;
      }
      if (item.estado_descripcion === "Rechazada") {
        return true;
      }
      if (item.estado === "REC") {
        return true;
      } else {
        return false;
      }
    },
    unselect() {
      this.itemSelected = null;

      this.$store.dispatch("Openusermodal");
    },

    BadgeColor(item) {
      if (item.plazo < 40) {
        return "red";
      }
      if (item.plazo < 70) {
        return "orange";
      }
      if (item.plazo > 70) {
        return "green";
      }
    },
  },
  computed: {
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage);
    },
  },
};
</script>
<style scoped src="../../assets/CSS/SimpleTable.css"></style>
